export function setDocumentDataAttribute(name: string, value?: string | null) {
  const root = document.documentElement;

  if (value === undefined || value === null) {
    delete root.dataset[name];
  } else {
    root.dataset[name] = value;
  }
}

export function setDocumentProperty(name: string, value: string = '') {
  const root = document.documentElement;
  root.style.setProperty(name, value);
}
